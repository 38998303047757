<template>
  <div class="w-full bg-qgo-secondary-bg">
    <!-- use this div to style user pages container e.g bg color, margins, etc -->
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  }
});
</script>
