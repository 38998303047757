<template>
  <queue-card-layout :ready="queue.order_status == 'Ready'">
    <div
      class="h-full border-t-2 border-l-2 border-r-2"
      :class="queue.order_status == 'Ready' ? 'border-qgo-primary-bg' : 'border-green-900'"
    >
      <div class="flex items-center justify-center h-3/5">
        <div class="">
          <p class="text-4xl font-black text-black">
            Q{{ queue.order_no }}
          </p>
        </div>
      </div>
      <div
        class="flex flex-col h-2/5"
        :class="queue.order_status === 'Collected' ? 'bg-qgo-primary-bg' : 'bg-qgo-secondary-bg'"
      >
        <span
          class="relative justify-center w-20 h-20 m-auto bg-gray-400 rounded-full justify-self-center -top-9"
        >
          <img
            v-if="queue.vendor_detail && queue.vendor_detail.logo"
            class="inline-block w-20 h-20 rounded-full"
            :src="queue.vendor_detail.logo"
          />
          <div
            class="relative flex items-center justify-center my-2 -mx-8 text-white -bottom-20"
          >
            <!-- <p v-if="queue.ready" class="">Ready to Collect</p>
            <p v-else>Preparing</p> -->
          </div>
        </span>
      </div>
    </div>
  </queue-card-layout>
</template>

<script>
import { defineComponent } from "vue";
import QueueCardLayout from "@/components/pages/QueueCardLayout.vue";

export default defineComponent({
  props: {
    queue: {
      type: Object
    }
  },
  components: {
    QueueCardLayout
  }
});
</script>
